.player-container {
  width: 30%;
  flex-direction: column;
  margin: 10px auto 0px;
  align-self: center;
  position: relative;
}

.player-grid-item {
  width: 100%; /* Take full width of the grid column */
  padding-top: 10px;
  margin-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #0a4d43;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
}

.more-button {
  align-self: center;
  width: 80px;
  height: 34px;
  background: transparent;
  border-radius: 100px;
  color: rgba(149, 68, 53, 1);
  font-size: 12px;
  border-width: 2;
  border-color: rgba(149, 68, 53, 1);
}

.more-button:hover {
  background: rgba(149, 68, 53, 1);
  color: white;
}

@media (max-width: 900px) {
  .player-container {
    width: 50%;
    flex-direction: column;
    margin: 10px auto 0px;
    align-self: center;
    position: relative;
  }
}

@media (max-width: 600px) {
  .player-container {
    width: 80%;
    flex-direction: column;
    margin: 10px 9% 0px;
    align-self: center;
    position: relative;
  }
  .player-grid-item {
    margin: 5px;
    font-size: 13px;
  }
}
