/* Base styling for Air Datepicker */
.air-datepicker {
  padding-bottom: 10px;
  width: 350px; /* Default width */
  font-size: 16px; /* Default font size */
  border-radius: 16px;
  padding-top: 10px;
}

.air-datepicker-body--day-name {
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 5px;
  border-radius: 13px;
  background: rgba(133, 131, 131, 0.1);
  color: black;
  font-size: 12px;
  font-weight: 600;
}

.air-datepicker-nav {
  border-bottom: none;
}

.air-datepicker--buttons {
  border-top: none;
}

.air-datepicker-nav--title {
  pointer-events: none;
}

/* Base styling for date cells */
.air-datepicker-cell {
  margin: 2px;
}

.air-datepicker-cell:hover {
  border-radius: 15px;
}

.air-datepicker-cell:focus {
  border-radius: 15px;
  background: rgba(149, 68, 53, 1);
  color: white;
}

.-selected-.air-datepicker-cell.-day-.-other-month- {
  background: rgb(234, 195, 188);
}

.-selected-.air-datepicker-cell.-day-.-other-month-.-focus- {
  background: rgb(234, 195, 188);
}

.air-datepicker-cell.-selected- {
  border-radius: 15px;
  background: rgba(149, 68, 53, 1);
  color: white;
}

.air-datepicker-cell.-selected-.-focus- {
  background: rgba(149, 68, 53, 1);
}
.air-datepicker-cell.-selected-.-current- {
  background: rgba(149, 68, 53, 1);
}
.air-datepicker-cell.-current- {
  background: #0d8f4e;
  border-radius: 15px;
  color: rgb(227, 227, 227);
}

.all-week-available {
  font-size: 15px;
  color: #0d8f4e;
}

.all-week-available:hover {
  background: rgba(149, 68, 53, 0);
  color: #0d8f4e;
}

.all-week-not-available {
  font-size: 15px;
  color: red;
}

.all-week-not-available:hover {
  background: rgba(149, 68, 53, 0);
  color: red;
}

@media (max-width: 992px) {
  .air-datepicker {
    width: 300px; /* Adjust width for smaller screens */
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .air-datepicker-cell {
    width: 35px; /* Adjust cell width for smaller screens */
    height: 35px; /* Adjust cell height for smaller screens */
    line-height: 35px; /* Center text vertically */
  }
}

@media (max-width: 763px) {
  .air-datepicker {
    width: 350px; /* Adjust width for smaller screens */
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .air-datepicker-cell {
    width: 30px; /* Adjust cell width for smaller screens */
    height: 30px; /* Adjust cell height for smaller screens */
    line-height: 30px; /* Center text vertically */
  }
}
