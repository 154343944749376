.login-container {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  background: rgba(242, 235, 225, 1);
  height: 100vh;
}

.custom-logo {
  width: 15vw;
  margin-top: 20px;
}

.input {
  width: 30vw;
  padding: 10px 10px;
  margin-right: 10px;
  border-radius: 13px;
}

.input-large {
  font-size: 16px; /* Adjust the font size */
  color: rgba(133, 131, 131, 1);
}

.sendOtpbtn {
  align-self: center;
  /* width: 150px; */
  background: rgba(149, 68, 53, 1);
  border-radius: 25px;
  color: white;
  padding: 8px 30px;
  border-color: rgba(149, 68, 53, 1);
  margin-top: 15px;
}

.sendOtpbtn:hover {
  background: rgba(149, 68, 53, 1);
  color: white;
}

.inputPhoneNumber {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.verify-Btn {
  align-self: center;
  width: 300px;
  background: rgba(149, 68, 53, 1);
  border-radius: 25px;
  color: white;
  padding: 10px 20px;
  border-color: rgba(149, 68, 53, 1);
}

.verify-Btn:hover {
  background: rgba(149, 68, 53, 1);
  color: white;
}

.otpContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mobileNumberInfo {
  margin: 20px 10px;
  color: rgba(133, 131, 131, 1);
}

.otp {
  margin: 8px 10px;
}

.getCodeInfo {
  margin-top: 7px;
}

.resendContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 20px;
}

.custom-link {
  color: rgba(149, 68, 53, 1);
}

.custom-link:hover {
  color: rgba(149, 68, 53, 1);
}

@media (max-width: 960px) {
  .sendOtpbtn {
    width: 100px;
    padding: 8px 10px;
  }
}

@media (max-width: 750px) {
  .login-container {
    justify-content: center;
  }

  .inputPhoneNumber {
    flex-direction: column;
  }

  .sendOtpbtn {
    margin-top: 15px;
  }

  .input {
    width: 80vw;
  }

  .custom-logo {
    width: 30vw;
  }
}
