.home-container {
  /* display: flex;
  flex-wrap: wrap; */
  /* justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
  gap: 20px;
  flex-direction: row; */
  background: rgba(242, 235, 225, 1);
  padding-bottom: 20px;
  padding-top: 60px;
}
.mob-list-container {
  padding: 10px;
  background: rgba(242, 235, 225, 1);
  border-radius: 10px;
  padding-top: 70px;
}
.mob-key-value {
  font-size: 16px;
  color: black;
  margin-right: 8px;
  /* text-align: center; */
}
.mob-item-container {
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.mob-item-qty-container {
  background-color: brown;
}

.outer-container {
  background: rgba(242, 235, 225, 1);
  height: 100vh;
}

/* Inner container styling */
.innerContainer {
  justify-content: center;
  align-items: center;
  /* gap: 10px;
  padding: 10px; */
}

/* Text styling */
.orders {
  font-size: 20px;
  color: black;
  font-weight: 600;
  padding-top: 20px;
  padding-left: 20px;
  /* text-align: center; */
}

.no-order {
  font-size: 20px;
  color: black;
  font-weight: 600;
  padding-top: 100px;
  padding-left: 20px;
  text-align: center;
}

@media (max-width: 600px) {
  .container {
    flex-direction: column;
  }

  .no-order {
    font-size: 10px;
  }
}
