.coach-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns per row */
  gap: 20px; /* Add spacing between items */
  justify-content: center; /* Center the grid items horizontally */
  width: 80%; /* Ensure the container takes full width */
  margin: 20px auto; /* Center the grid container itself horizontally */
}

.coach-grid-item {
  width: 100%; /* Take full width of the grid column */
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #0a4d43;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
}
.coach-grid-item-disabled {
  width: 100%; /* Take full width of the grid column */
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #0a4d43;
  border-radius: 10px;
  background-color: #eeeeee;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
}

.book-coach-Btn {
  align-self: center;
  width: 300px;
  background: rgba(149, 68, 53, 1);
  border-radius: 25px;
  color: white;
  padding: 10px 20px;
  border-color: rgba(149, 68, 53, 1);
}
.book-coach-Btn-disable {
  align-self: center;
  width: 300px;
  background: rgba(149, 68, 53, 0.5);
  border-radius: 25px;
  color: white;
  padding: 10px 20px;
  border-color: rgba(149, 68, 53, 0);
}

.book-coach-Btn:hover {
  background: rgba(149, 68, 53, 1);
  color: white;
}

.coach-grid-item.selected {
  background-color: rgba(
    149,
    68,
    53,
    1
  ); /* Change background color when selected */
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.coach-grid-item-transparent {
  width: 100%; /* Take full width of the grid column */
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  display: inline-block;
}

.coach-grid-item.half-colored {
  background-image: linear-gradient(
    to right,
    rgba(149, 68, 53, 1) 50%,
    white 50%
  );
  color: rgb(208, 194, 194);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.radio-button-outer {
  border-radius: 50%;
  border-color: #0a4d43;
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  align-items: center;
  display: flex;
}

.radio-button-inner {
  border-radius: 50%;
  background-color: #0a4d43;
  width: 6px;
  height: 6px;
  align-self: center;
}

.more-button {
  align-self: center;
  width: 63px;
  height: 34px;
  background: transparent;
  border-radius: 100px;
  color: rgba(149, 68, 53, 1);
  font-size: 12px;
  border-width: 2;
  border-color: rgba(149, 68, 53, 1);
}

.more-button:hover {
  background: rgba(149, 68, 53, 1);
  color: white;
}

.available-coaches-text {
  margin-top: 20px;
  font-size: 17;
  text-align: center;
  color: #954435;
  font-weight: 600;
}

@media (max-width: 900px) {
  .coach-grid-container {
    grid-template-columns: repeat(2, 1fr); /* 3 columns per row */
  }

  .book-coach-Btn {
    width: 250px;
    border-radius: 18px;
    padding: 12px 15px;
  }

  .book-coach-Btn-disable {
    width: 250px;
    border-radius: 18px;
    padding: 12px 15px;
  }
}

@media (max-width: 600px) {
  .available-coaches-text {
    margin-top: 0px;
    font-size: 17;
    text-align: center;
    color: #954435;
    font-weight: 600;
  }
  .coach-grid-container {
    gap: 10px; /* Add spacing between items */
    width: 90%;
  }
  .coach-grid-item {
    margin: 5px;
    font-size: 13px;
  }

  .coach-grid-container {
    grid-template-columns: repeat(1, 1fr); /* 3 columns per row */
  }

  .book-coach-Btn {
    width: 200px;
    border-radius: 15px;
    padding: 12px 15px;
  }

  .book-coach-Btn-disable {
    width: 200px;
    border-radius: 15px;
    padding: 12px 15px;
  }
}
