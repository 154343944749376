.coach-container {
  display: flex;
  flex-wrap: wrap; /* Ensure items wrap to next row if space is not enough */
  justify-content: space-evenly; /* Align items horizontally */
  align-items: center;
  padding-bottom: 20px;
  gap: 20px; /* Add unit 'px' */
  flex-direction: row;
  background: rgba(242, 235, 225, 1);
  padding-top: 80px;
}

.coach-outer-container {
  background: rgba(242, 235, 225, 1);
  height: 100vh;
}

/* Inner container styling */
.coach-innerContainer {
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

/* Text styling */
.coach-this-week {
  font-size: 20px;
  color: black;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 600px) {
  .coach-container {
    flex-direction: column;
  }
}
