.coach-plans-container {
  display: flex;
  flex-wrap: wrap; /* Ensure items wrap to next row if space is not enough */
  justify-content: space-evenly; /* Align items horizontally */
  align-items: center;
  padding-bottom: 20px;
  gap: 20px; /* Add unit 'px' */
  flex-direction: row;
  background: rgba(242, 235, 225, 1);
  padding-top: 80px;
  height: 100%;
}
.box {
  display: flex;
  flex-wrap: wrap;
}

.coach-plans-outer-container {
  background: rgba(242, 235, 225, 1);
  height: 100vh;
}

/* Inner container styling */
.coach-innerContainer {
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

/* Text styling */
.coach-this-week {
  font-size: 20px;
  color: black;
  font-weight: 600;
  text-align: center;
}

.coach-plans-grid-container {
  display: flex;
  flex-direction: column; /* Stack weeks vertically */
  gap: 20px; /* Space between weeks */
  margin: 50px auto auto;
}

.time-slot-row {
  flex-direction: row;
  gap: 20px;
}

.coach-plans-grid-item {
  flex: 1 1 auto; /* Allow items to grow and shrink */
  width: 150px; /* Minimum width for each slot */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
}

.date-value {
  font-size: 16px;
  color: brown;
  margin-right: 8px;
  /* text-align: center; */
}

.coach-plans-grid-item-mobile {
  flex: 1 1 auto; /* Allow items to grow and shrink */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  border: 1px solid #ccc;
  border-radius: 45px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 10px;
  margin-top: 3px;
}
.coach-plans-grid-item-mobile.selected {
  background-color: rgba(149, 68, 53, 1);
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.coach-plans-grid-item.selected {
  background-color: rgba(149, 68, 53, 1);
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}
.coach-plans-grid-item-mobile.half-colored {
  background-image: linear-gradient(
    to right,
    rgba(149, 68, 53, 1) 50%,
    white 50%
  );
  color: rgb(208, 194, 194);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}
.time-slot-box-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.time-slot-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.coach-plans-grid-item-transparent {
  width: 100%; /* Take full width of the grid column */
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  display: inline-block;
}

.coach-plans-grid-item.half-colored {
  background-image: linear-gradient(
    to right,
    rgba(149, 68, 53, 1) 50%,
    white 50%
  );
  color: rgb(208, 194, 194);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.coach-plans-grid-item.disabled {
  /* background-image: linear-gradient(
    to right,
    rgba(149, 68, 53, 1) 50%,
    white 50%
  ); */
  background-color: rgb(244, 244, 244);
  color: rgb(208, 194, 194);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19); */
}

.coach-plans-title {
  text-align: center; /* Center the text */
  width: 100%; /* Take full width to ensure centering */
  margin: 20px 0px; /* Add margin for spacing */
  font-weight: bold; /* Optional: make the title bold */
  font-size: 18px; /* Adjust the font size as needed */
}

.time-slot-box.unavailable {
  background-color: lightgray;
}

.mobile-item-container {
  border-color: brown;
  border-width: 2px;
}

.plan-mob-list-container {
  padding: 10px;
  background: rgba(242, 235, 225, 1);
  border-radius: 10px;
}

.coach-plans-header {
  text-align: center; /* Center the text */
  width: 100%; /* Take full width to ensure centering */
  margin-top: 20px; /* Add margin for spacing */
  font-weight: bold; /* Optional: make the title bold */
  font-size: 20px; /* Adjust the font size as needed */
}

.coach-plans_info-container {
  text-align: center;
  margin-top: 30px;
}
.coach-info {
  color: rgba(149, 68, 53, 1);
  font-size: 17px;
  font-weight: 500;
  display: inline-block; /* Ensures that the span only takes up as much width as needed */
}

@media (max-width: 768) {
  .coach-plans-grid-container {
    grid-template-columns: repeat(3, 1fr); /* 3 columns per row */
  }
}

@media (max-width: 600px) {
  .coach-plans-grid-container {
    gap: 10px;
    width: 90%;
  }

  .coach-plans-grid-container {
    grid-template-columns: repeat(3, 1fr); /* 3 columns per row */
  }
}

@media (max-width: 600px) {
  .coach-plans-container {
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .coach-plans-grid-item {
    flex: 1 1 48%; /* 2 items per row */
  }
}

@media (max-width: 900px) {
  .coach-plans-grid-item {
    flex: 1 1 30%; /* 3 items per row */
  }
  .coach-info {
    font-size: 13px;
  }
}

@media (max-width: 600px) {
  .coach-plans-grid-item {
    flex: 1 1 100%; /* 1 item per row */
  }
}
