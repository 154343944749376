/* styles.css */

.coach-timeslot-grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 3 columns per row */
  gap: 20px; /* Add spacing between items */
  justify-content: center; /* Center the grid items horizontally */
  width: 100%; /* Ensure the container takes full width */
  max-width: 800px; /* Adjust this value to control the maximum width of the container */
  margin: 50px auto; /* Center the grid container itself horizontally */
}

.coach-timeslot-grid-item {
  width: 100%; /* Take full width of the grid column */
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
}

.coach-timeslot-grid-item.selected {
  background-color: rgba(
    149,
    68,
    53,
    1
  ); /* Change background color when selected */
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.coach-timeslot-grid-item-transparent {
  width: 100%; /* Take full width of the grid column */
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  display: inline-block;
}

.coach-timeslot-grid-item.half-colored {
  background-image: linear-gradient(
    to right,
    rgba(149, 68, 53, 1) 50%,
    white 50%
  );
  color: rgb(208, 194, 194);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.coach-timeslot-grid-item.disabled {
  /* background-image: linear-gradient(
    to right,
    rgba(149, 68, 53, 1) 50%,
    white 50%
  ); */
  background-color: rgb(244, 244, 244);
  color: rgb(208, 194, 194);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19); */
}

@media (max-width: 768) {
  .coach-timeslot-grid-container {
    grid-template-columns: repeat(3, 1fr); /* 3 columns per row */
  }
}

@media (max-width: 600px) {
  .coach-timeslot-grid-container {
    gap: 10px; /* Add spacing between items */
    width: 90%;
  }
  .coach-timeslot-grid-item {
    margin: 5px;
    font-size: 13px;
  }

  .coach-timeslot-grid-container {
    grid-template-columns: repeat(3, 1fr); /* 3 columns per row */
  }
}
