.getstarted-container {
  display: flex;
  flex-wrap: wrap; /* Ensure items wrap to next row if space is not enough */
  justify-content: space-evenly; /* Align items horizontally */
  align-items: center;
  padding-bottom: 20px;
  gap: 20px; /* Add unit 'px' */
  flex-direction: row;
  background: rgba(242, 235, 225, 1);
  padding-top: 100px;
}

/* Inner container styling */
.getstarted-innerContainer {
  background-color: white;
  display: flex;
  width: 400px;
  height: 25vh;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
/* Text styling */
.getstarted-title {
  font-size: 20px;
  color: black;
  align-self: center;
  font-weight: 600;
  /* text-align: center; */
}
.getstarted-icon {
  height: 10vh;
  align-self: center;
}

@media (max-width: 992px) {
  .getstarted-innerContainer {
    background-color: white;
    display: flex;
    width: 340px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
  }
}

@media (max-width: 600px) {
  .getstarted-container {
    flex-direction: column;
  }
  .getstarted-innerContainer {
    background-color: white;
    display: flex;
    width: 80%;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
  }
}
