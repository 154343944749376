.order-desc-container {
  background: rgba(242, 235, 225, 1);
  height: 100vh;
  padding-top: 60px;
}

.order-desc-home-container {
  background: rgba(242, 235, 225, 1);
}
.mob-list-container-desc {
  padding: 10px;
  border-radius: 10px;
}
/* Inner container styling */
.order-desc-innerContainer {
  justify-content: center;
  align-items: center;
  /* gap: 10px;
  padding: 10px; */
}

/* Text styling */
.order-desc-orders {
  font-size: 20px;
  color: black;
  font-weight: 600;
  padding-top: 20px;
  padding-left: 20px;
  /* text-align: center; */
}

.order-desc-no-order {
  font-size: 20px;
  color: black;
  font-weight: 600;
  padding-top: 50px;
  padding-left: 20px;
  text-align: center;
}

@media (max-width: 600px) {
  .order-desc-no-order {
    font-size: 10px;
  }
}
