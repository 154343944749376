.book-pro-container {
  background: rgba(242, 235, 225, 1);
  height: 100vh;
  padding-top: 60px;
  padding-bottom: 100px;
}

.book-pro-container-full {
  background: rgba(242, 235, 225, 1);
  height: 100%;
  padding-top: 60px;
  padding-bottom: 30px;
}

.book-pro_info-container {
  text-align: center;
  margin-top: 20px;
}
.book-pro_info {
  color: rgba(149, 68, 53, 1);
  font-size: 17px;
  font-weight: 500;
  display: inline-block; /* Ensures that the span only takes up as much width as needed */
}
.airDatePicker-container {
  display: flex; /* Enables Flexbox layout */
  justify-content: center; /* Centers the inner container horizontally */
  align-items: center; /* Centers the inner container vertically (optional) */
  margin-top: 50px;
}

.airDatePicker-inner-container {
  display: flex; /* Enables Flexbox layout for child elements */
  flex-direction: column; /* Stacks children vertically */
  justify-content: center; /* Centers child elements vertically */
  align-items: center; /* Centers child elements horizontally */
  gap: 10px; /* Adds spacing between child elements */
  padding: 10px; /* Adds padding around the inner container */
}

.courttabs-container {
  display: flex; /* Enables Flexbox layout */
  flex-direction: column;
  justify-content: center; /* Centers the inner container horizontally */
  align-items: center; /* Centers the inner container vertically (optional) */
  margin-top: 30px;
}

.select-court-text {
  color: rgb(200, 127, 114);
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 500;
}

/* Text styling */
.date-info {
  color: rgba(149, 68, 53, 1);
  font-size: 17px;
  font-weight: 500;
  text-align: center;
}

.indoor-tab {
  margin-right: 10px;
  padding: 6px 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}

.button-group-full-width {
  display: flex;
  width: 30%; /* Ensure the ButtonGroup takes the full width of its container */
}

/* Ensure ToggleButton takes equal width */
.toggle-button-full-width {
  flex: 1; /* Allows buttons to grow and shrink equally */
  margin: 0; /* Remove margin between buttons */
  border-radius: 10px; /* Optional: remove border radius for a consistent look */
}

.toggle-button-full-width + .toggle-button-full-width {
  border-left: 1px solid #ccc;
}

.hr {
  border: 0;
  border-top: 1px solid #ccc; /* Customize the line style */
  margin: 10px 0; /* Space above and below the line */
}

@media (max-width: 992px) {
  .book-pro_info {
    font-size: 13px;
  }

  .date-info {
    font-size: 13px;
  }

  .button-group-full-width {
    width: 50%; /* Ensure the ButtonGroup takes the full width of its container */
  }
}

@media (max-width: 600px) {
  .book-pro_info {
    font-size: 13px;
  }

  .date-info {
    font-size: 13px;
  }

  .button-group-full-width {
    width: 70%;
  }
  .airDatePicker-container {
    margin-top: 20px;
  }
  .book-pro_info-container {
    margin-top: 10px;
  }
  .courttabs-container {
    margin-top: 20px;
  }
}
