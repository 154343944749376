.search-bar {
  width: 30%;
  margin: 20px auto 0; /* Horizontally center the element and add top margin */
  align-self: center;
  position: relative;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box; /* Ensure padding is included in the width */
  transition: border-color 0.3s; /* Smooth transition for border color change */
  border-width: 2px;
}

/* Change border color when input is focused */
.search-bar input:focus {
  border-color: rgba(
    149,
    68,
    53,
    1
  ); /* Change border color to red when focused */
  outline: none; /* Remove default browser outline */
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  z-index: 1000;
  border-color: rgba(149, 68, 53, 1);
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  list-style: none; /* Remove default list styling */
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

@media (max-width: 992px) {
  .search-bar {
    width: 50%;
  }

  .search-bar input {
    font-size: 17px;
  }
}

@media (max-width: 600px) {
  .search-bar {
    width: 80%;
  }

  .search-bar input {
    font-size: 17px;
  }
}
