.custom-button {
  border-radius: 10px;
  border-color: #954435;
  color: #954435;
  background-color: transparent;
}

.custom-button:hover {
  border-color: #954435;
  background-color: #954435;
  color: #ffffff;
}

.custom-button:focus {
  background-color: #732d27;
  border-color: #954435;
  color: #ffffff;
}

.custom-button:active {
  background-color: #732d27;
  border-color: #954435;
  color: #ffffff;
}

.custom-button.-selected- {
  background-color: #732d27;
  border-color: #954435;
  color: #ffffff;
}
