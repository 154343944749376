.topBar {
  background-color: black;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 400px;
}

.coachText {
  color: white;
  font-size: 20px;
  align-self: center;
  text-align: center;
  position: absolute;
  left: 100px;
  right: 100px;
}

.logo {
  height: 40px;
  align-self: center;
}

.logout-button {
  margin-right: 10px;
  padding: 6px 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}

.bookPro-button {
  margin-right: 10px;
  padding: 6px 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}

@media (max-width: 992px) {
  .topBar {
    padding: 10px 5px;
  }
  .coachText {
    font-size: 15px;
  }
  .logo {
    height: 40px;
  }
}

@media (max-width: 992px) {
  .topBar {
    padding: 10px 5px;
  }
  .coachText {
    font-size: 13px;
  }
  .logo {
    height: 30px;
  }
}

@media (max-width: 600px) {
  .topBar {
    padding: 5px;
    /* justify-content: center; Center items horizontally */
  }
  .coachText {
    font-size: 10px;
  }
  .logo {
    height: 20px;
  }
}
