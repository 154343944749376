.table-container {
  width: 80vw;
  margin: 30px auto; /* Auto margin to center horizontally */
  border-radius: 10px;
  background-color: rgb(233, 214, 186);
  /* padding: 20px; */
  /* border: 1px solid rgb(202, 121, 8); */
}

.custom-table-container {
  width: 100%; /* Adjust width as needed */
  border-collapse: collapse; /* Ensures borders between cells are collapsed */
}

.custom-header {
  padding: 20px;
  background-color: rgb(143, 114, 74);
  color: white;
}

.fix-header {
  width: 200px;
}

.table-item {
  border-top: none; /* Remove top border if necessary */
  border-right: none; /* Remove right border if necessary */
  border-left: none; /* Remove left border if necessary */
  border-bottom: 1px solid rgba(242, 235, 225, 1); /* Add border to bottom only */
  padding: 20px;
  max-width: 200px; /* Example: Set a maximum width for the cell */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Display an ellipsis (...) to indicate text overflow */
  white-space: nowrap; /* Prevent text from wrapping */
}

.table-data:hover {
  background-color: rgb(243, 224, 198);
}

.verifyBtn {
  background: rgba(149, 68, 53, 1);
  width: 100px;
  border-radius: 15px;
  color: white;
  padding: 5px 5px;
  border-color: rgba(149, 68, 53, 1);
  text-align: center;
  margin-bottom: 2px;
}

.cancelBtn {
  border-width: 2px;
  width: 100px;
  font-weight: bold;
  background-color: white;
  border-radius: 20px;
  color: rgba(149, 68, 53, 1);
  padding: 5px 5px;
  margin-right: 10px;
  border-color: rgba(149, 68, 53, 1);
  text-align: center;
}

.verifyBtn:hover {
  background: transparent;
  color: rgba(149, 68, 53, 1);
  border-color: rgba(149, 68, 53, 1);
}

.cancel {
  background: transparent;
  width: 100px;
  border-radius: 15px;
  color: rgba(149, 68, 53, 1);
  padding: 5px 5px;
  border-color: rgba(149, 68, 53, 1);
  text-align: center;
  margin-left: 10px;
  margin-bottom: 2px;
}

.cancel:hover {
  background: rgba(149, 68, 53, 1);
  color: white;
}

@media (max-width: 768px) {
  .custom-header.hidden-on-mobile,
  .table-item.hidden-on-mobile {
    display: none; /* Hide headers and cells with hiddenOnMobile flag on small screens */
  }
  .table-container {
    width: 80vw;
    border-radius: 5px;
  }
  .table-item {
    padding: 5px;
    max-width: 100px;
    font-size: 10px;
  }

  .custom-header {
    padding: 5px;
    font-size: 10px;
  }

  .fix-header {
    width: 100px;
  }

  .verifyBtn {
    background: rgba(149, 68, 53, 1);
    width: 100px;
    border-radius: 20px;
    color: white;
    padding: 5px 5px;
    border-color: rgba(149, 68, 53, 1);
    text-align: center;
  }

  .cancel {
    width: 40px;
    border-radius: 5px;
    font-size: 8px;
    padding: 3px 3px;
    margin-bottom: 5px;
    margin-left: 5px;
  }
}
